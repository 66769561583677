$main     : #33ACBD;

$mxb-line_height: 1.428;

@mixin contentStyle() {
  padding: 64px;
  width: 100%;
}

//Breakpoint
$MXB-XL   : 1200px;
$MXB-L    : 1024px;
$MXB-M    : 740px;
$MXB-S    : 480px;
$MXB-XS   : 360px;
