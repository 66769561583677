@keyframes up_down {
  0% {
      -webkit-transform: translateY(0) translateX(0);
      transform: translateY(0) translateX(0)
  }

  25% {
      -webkit-transform: translateY(-1.875rem) translateX(0);
      transform: translateY(-1.875rem) translateX(0)
  }

  75% {
      -webkit-transform: translateY(1.875rem) translateX(0);
      transform: translateY(1.875rem) translateX(0)
  }
}
